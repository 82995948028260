.project-card {
    background-color: black;
    border-radius: 15px;
    overflow: hidden;
    color: #fff;
    box-shadow: 0 5px 20px rgba(0, 255, 255, 0.6);
    text-align: center;
    margin: 20px;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-10px);
}

.project-image {
    width: 100%;
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.project-title {
    font-size: 1.5rem;
    color: #8bf;
    text-shadow: 0 0 20px #0ff, 0 0 2px #0ff;
}

.project-summary {
    font-size: 1rem;
    padding: 0 15px;
    color: #ccd;
}

.project-tags {
    margin: 15px 0;
}

.project-tag {
    display: inline-block;
    margin: 0 5px;
    padding: 5px;
    background-color: #222;
    border-radius: 5px;
    text-shadow: 0 0 5px #0ff, 0 0 10px #0ff;
}

.project-tag:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: rgba(0, 255, 255, 0.259);
    filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.project-tag:hover:before {
    opacity: 1;
}