.navigation-tabs {
  display: flex;
  justify-content: center;
  background-color: #000000;
  padding: 1rem;
  margin-top: 5%;
  width: 50vw;
  margin: auto;

  button {
    background-color: #222;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.5vw 0.7vw;
    margin: 0 0.2vw;
    font-size: 1.2vw;
    transition: all 0.3s ease;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.1vw;
  }

  @media (orientation: landscape) {

    display: flex;
    justify-content: center;
    background-color: #000000;
    padding: 1rem;
    margin-top: 5%;
    width: 30vw;
    margin: auto;

    button {
      background-color: #222;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0.5vw 0.9vw;
      margin: 0 0.2vw;
      font-size: 0.8vw;
      transition: all 0.3s ease;
      position: relative;
      text-transform: uppercase;
      letter-spacing: 0.1vw;
    }


  }
}

.navigation-tabs {
  button {

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      z-index: -1;
      background: #08f;
      filter: blur(5px);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover,
    &:focus,
    &.active {
      box-shadow: 0 0 20px #08f, 0 0 40px #08f, 0 0 60px #08f;
      text-shadow: 0 0 5px #08f, 0 0 10px #08f;

      &::before,
      &::after {
        opacity: 1;
      }
    }

    &.active {
      box-shadow: 0 0 10px #0bf, 0 0 20px #0bf, 0 0 30px #0bf;
    }
  }
}