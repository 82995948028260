.contact-list {
    list-style: none;
    padding: 0;

    li {

        a {
            color: #fff;
            text-decoration: none;
            font-size: 1.5rem;
            border: none;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            text-shadow: 0 0 5px rgba(0, 150, 255, 0.75),
                0 0 10px rgba(0, 150, 255, 0.75),
                0 0 20px rgba(0, 150, 255, 0.75),
                0 0 40px rgba(0, 150, 255, 0.75),
                0 0 80px rgba(0, 150, 255, 0.75),
                0 0 120px rgba(0, 150, 255, 0.75);

            &:hover {
                color: #9df;
                text-shadow: 0 0 10px rgba(0, 150, 255, 1),
                    0 0 20px rgba(0, 150, 255, 1),
                    0 0 30px rgba(0, 150, 255, 1),
                    0 0 40px rgba(0, 150, 255, 1),
                    0 0 70px rgba(0, 150, 255, 1),
                    0 0 80px rgba(0, 150, 255, 1),
                    0 0 100px rgba(0, 150, 255, 1),
                    0 0 150px rgba(0, 150, 255, 1);
            }
        }
    }
}