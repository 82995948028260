.scene {
  position: relative;
  width: 100vw;
  height: 100vh;
  perspective-origin: center;
  transition: transform 1s, perspective 1s;

  &.zoom-out {
    transform: scale(0.6);
  }
}

.cube {
  width: 60vw;
  height: 60vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transition: transform 1s;
  transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg);

  &.turning {
    transition: transform 2s;
    transform: translate(-50%, -50%) rotateX(var(--rotate-x)) rotateY(var(--rotate-y));
  }
}

.cube-face {
  transform-origin: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  backface-visibility: hidden;


}

.face-0 {
  transform: rotateY(0deg) translateZ(30vw);
}

.face-1 {
  transform: rotateY(90deg) translateZ(30vw);
}

.face-2 {
  transform: rotateY(180deg) translateZ(30vw);
}

.face-3 {
  transform: rotateY(-90deg) translateZ(30vw);
}

.face-4 {
  transform: rotateX(90deg) translateZ(30vw);
}

.face-5 {
  transform: rotateX(-90deg) translateZ(30vw);
}

.page-content {
  width: 50vw;
  height: 50vh;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (orientation: portrait) {
    width: 60vw;
    height: 70vh;
    max-height: 75%;
  }

  @media (orientation: landscape) {
    width: 37vw;
    height: 70vh;
    max-height: 75%;
    overflow-y: auto;
    margin: auto;
  }
}

@media (orientation: landscape) {
  .cube {
    width: 40vw;
    height: 40vw;
  }

  .face-0 {
    transform: rotateY(0deg) translateZ(20vw);
  }

  .face-1 {
    transform: rotateY(90deg) translateZ(20vw);
  }

  .face-2 {
    transform: rotateY(180deg) translateZ(20vw);
  }

  .face-3 {
    transform: rotateY(-90deg) translateZ(20vw);
  }

  .face-4 {
    transform: rotateX(90deg) translateZ(20vw);
  }

  .face-5 {
    transform: rotateX(-90deg) translateZ(20vw);
  }
}