.art-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.canvas-wrapper {
  max-width: 100%;
  margin: 0px;
  position: relative;
  width: 100%;
  padding-top: 0%;
  background: linear-gradient(to top, rgba(18, 18, 81, 1), rgba(18, 18, 81, 0));

  canvas {
    width: 800px;
    height: 400px;

    h1 {
      margin: 0;
    }
  }
}

.lightbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.track-vertical {
  background-color: black;
  border-radius: 3px;
  right: 2px;
  bottom: 2px;
  top: 2px;
}

.thumb-vertical {
  background-color: #39ff14;
  border-radius: 3px;
  box-shadow: 0 0 6px 2px rgba(57, 255, 20, 0.8);
}