.presentation {
  color: white;
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-photo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3);
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  &:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.6), 0 0 40px rgba(255, 255, 255, 0.6);
  }
}


.presentation-text {
  h1 {
    margin-bottom: 15px;
    font-size: 2.5em;
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    max-width: 800px;
    margin-bottom: 10px;
  }
}